import ReactPlayer from "react-player";
import Box from "@mui/material/Box";
export default function Player({ url }) {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ReactPlayer
          controls={true}
          url={url}
          playing={true}
          style={{
            maxWidth: "100%",
          }}
          playsinline={true}
          config={{
            youtube: {
              playerVars: {
                enablejsapi: 1
              }
            }
          }
          }
        />
      </div>
      <Box
        style={{
          marginTop: 20,
        }}
      ></Box>
    </Box>
  );
}
